// Bootstrap variables

$font-family-sans-serif: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$body-color: #515151 !default;

$primary:       #3F84FC !default;
$secondary:     #727F94 !default;
$success:       #1DAB47 !default;
$info:          #8A3FFC !default;
$warning:       #FCAE3F !default;
$danger:        #FC413F !default;
$light:         #f8f9fa !default;
$dark:          #1E232D !default;

$border-radius: 0 !default;

// Spur variables
$logo-color: #576177 !default;
$toolbar-bg: #F3F3F3 !default;

$dash-nav-dark-color: #fff !default;
$dash-nav-dark-hover-bg: rgba(#fff, .04) !default;
$dash-nav-dark-bg: #181F2C !default;

$searchbox-input-placeholder: #8b8b8b !default;

