.table-in-card {
  margin: -12px 0;

  thead {
    tr {
      th {
        border-top-color: transparent;
      }
    }
  }
}

.card-body-with-dark-table {
  background-color: #212529;
}
