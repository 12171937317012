.notifications {
  margin: -8px -32px;

  @include media-breakpoint-down(sm) {
    margin: -8px -18px;
  }
}

.notification {
  display: flex;
  flex-direction: row;
  padding: 8px 32px;
  color: $body-color;

  &:hover,
  &:focus,
  &:active {
    color: $body-color;
    text-decoration: none;
    background-color: #F0F1F7;
  }
}

.notification-text {
}

.notification-icon {
  //margin-right: 20px;
  width: 35px;
}

.notification-time {
  margin-left: auto;
  opacity: .8;
}

.notifications-show-all {
  margin-top: 8px;
  margin-left: 67px;

  a {
    color: $body-color;
    text-decoration: underline;

    &:focus,
    &:hover,
    &:active {
      color: $body-color;
    }

  }
}
